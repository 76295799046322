<template>
  <div class="ShopDetail">
    <header
      v-if="
        msg != 'point' && $route.name != '店铺详情' && $route.name != '商品分类'
      "
    >
      <div class="container">
        <img src="@/assets/images/logo.png" alt="" />
        <div class="advisoryByphone">
          <img src="@/assets/images/advisoryByphone.png" />
          <div>购墓咨询热线</div>
          <div>400-119-0811</div>
        </div>
        <div class="advisoryOnline">
          <img src="@/assets/images/advisoryOnline.png" />
          <div>专业解答墓地相关问题</div>
          <div>在线咨询</div>
        </div>
      </div>
    </header>
    <div class="nav" v-if="msg != 'point'">
      <ul class="container">
        <img :src="listData && listData.logo" alt="" class="imgLogo" />
        <div class="contentBox" v-if="follow" @click="followClick()">关注</div>
        <div class="contentBoxO" v-else  @click="followClick()">取消关注</div>
        <div class="contentBoxT"></div>
        <li
          v-for="(item, index) in navListT"
          :key="index"
          :class="curIdx == index && 'active'"
          @click="clickACt(item, index)"
        >
          {{ item.name }}
        </li>
      </ul>
    </div>
    <template v-if="$route.path == '/ShopDetail'">
      <Carousel
        v-model="value3"
        :autoplay="setting.autoplay"
        :autoplay-speed="setting.autoplaySpeed"
        :dots="setting.dots"
        :radius-dot="setting.radiusDot"
        :trigger="setting.trigger"
        :arrow="setting.arrow"
        :height="673"
        loop
        v-if="listData && listData.bannerList && listData.bannerList.length"
      >
        <CarouselItem v-for="(item,idx) in listData.bannerList" :key="idx">
          <img :src="item.path" />
        </CarouselItem>
      </Carousel>
      <div class="ShopDetail_Bg"></div>
      <!-- <div class="goodsList container">
        <ul>
          <li v-for="item in 5">
            <img src="">
            <div>某某骨灰盒</div>
          </li>
        </ul>
      </div> -->
      <div class="xsActive">
        <h1 class="title">限时活动</h1>
         <!-- @click="routeActiveClick(item.activity_id)" -->
        <div class="sweepActivitiesB">
          <div class="sweepActivitiesBox" v-for="item in RecordPageList"  @click="routeActiveClick(item.activity_id)">
             <div class="sweepActivitiesLeft">
              <img :src="item.head_pic" alt="">
            </div>
            <div class="sweepActivitiesRight">
                <div>{{item.activity_name}}</div>
                <div>{{item.description}}</div>
                <div>{{item.activity_start_time}}-{{item.activity_end_time}}</div>
            </div>
          </div>
        </div>
        <div
            class="moreBtn"
            @click="routeTo('/activeSh',{companyId: companyId,businessId:businessId})"
          >查看更多 <i class="iconfont iconarrow-right"></i></div>
      </div>

      <div class="container">
        <div class="title">热卖商品</div>
        <div class="hotGoods" v-if="hotGoods">
          <div class="hotGoods_top" v-if="hotGoods[0]">
            <div class="hotGoods_top_left">
              <img :src="hotGoods[0].goodsPic" @click="goodsDetail({ ...hotGoods[0], companyId: companyId })" />
            </div>
            <div class="hotGoods_top_right">
              <div class="idx">NO.1</div>
              <div class="goodsName">{{ hotGoods[0].goodsName }}</div>
              <p class="goodsDesc"></p>
              <div class="goodsBtn">
                <span class="goodsPrice"
                  >¥{{ hotGoods[0].goodsDiscountPrice }}</span
                >
                <span
                  class="goodsPayBtn"
                  @click="goodsDetail({ ...hotGoods[0], companyId: companyId })"
                  >立即购买</span
                >
              </div>
            </div>
          </div>
          <div class="hotGoodsList">
            <ul :style="`transform: translateX(-${128 * clickCount}px);`">
              <li v-if="hotGoods[1]">
                <img
                  :src="hotGoods[1].goodsPic"
                  @click="goodsDetail({ ...hotGoods[1], companyId: companyId })"
                />
              </li>
              <li v-if="hotGoods[2]">
                <img
                  :src="hotGoods[2].goodsPic"
                  @click="goodsDetail({ ...hotGoods[2], companyId: companyId })"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="title">推荐商品</div>
        <div class="goodListType1">
          <ul>
            <li v-for="(item, index) in newgoods" :key="index"  @click="goodsDetail({ ...item, companyId: companyId })">
              <img :src="item.goodsPic" />
              <div class="goodsName">{{ item.goodsName }}</div>
              <p class="goodsDesc"></p>
              <div class="goodsBtn">
                <span class="goodsPrice">¥{{ item.goodsDiscountPrice }}</span>
                <span
                  class="goodsPayBtn"
                  >立即购买</span
                >
              </div>
            </li>
          </ul>
        </div>
      </div>
    </template>
    <template v-else>
      <router-view
        @point="point"
        @businessIdT="businessIdT"
        @companyIdT="companyIdT"
      />
    </template>
    <div class="kfBox" @click="onlineClick">
      <img :src="require('@/assets/images/kf.png')" alt="" />
    </div>
  </div>
</template>

<script>
import api from "@/util/api";
// import bus from "@/util/eventBus";
import instance from '@/util/instance'
export default {
  data() {
    return {
      self: null,
      msg: "",
      isShowVideo: false,
      value3: 0,
      setting: {
        autoplay: false,
        autoplaySpeed: 3000,
        dots: "inside",
        radiusDot: true,
        trigger: "click",
        arrow: "always",
      },
      businessId: sessionStorage.getItem("businessId") || null,
      companyId: sessionStorage.getItem("companyId") || 0,
      curIdx: sessionStorage.getItem("curIdx") || null,
      hotGoodsCurIdx: 0,
      navListT: [],
      clickCount: 0,
      hotGoodsList: [],
      listData: [],
      hotGoods: [],
      newgoods: [],
      allList: [],
      userInfo: JSON.parse(sessionStorage.getItem("userInfo") || "{}"),
      RecordPageList:[],
      total: 0, //总条数
      size:10,
      current:1,
      follow:true,
    };
  },
  watch: {
    msg: {
      deep: true,
      handler: function (newV, oldV) {
        this.msg = newV;
        // console.log(newV);
      },
    },
    businessId: {
      deep: true,
      handler: function (newV, oldV) {
        console.log("111222333",newV,"231");
        this.businessId = newV;
        this.detailsList();
        this.hotGoodsList = Array.from({ length: 20 });
        this.categoryAllList();
        this.search();
      },
    },
    companyId: {
      deep: true,
      handler: function (newV, oldV) {
        this.companyId = newV;
        console.log("132", newV);
        this.detailsList();
        this.hotGoodsList = Array.from({ length: 20 });
        this.categoryAllList();
        this.search();
      },
    },
  },
  mounted() {
      if(sessionStorage.getItem("navListT")){
        this.navListT = JSON.parse(sessionStorage.getItem("navListT"));
      }
      if(sessionStorage.getItem("listData") && sessionStorage.getItem("newgoods")){
        this.listData = JSON.parse(sessionStorage.getItem("listData"));
        this.newgoods = JSON.parse(sessionStorage.getItem("newgoods"));
      }
      if(sessionStorage.getItem("hotGoods")){
        this.hotGoods = JSON.parse(sessionStorage.getItem("hotGoods"));
      }
    console.log(111,sessionStorage.getItem("businessId"));
    this.businessId=sessionStorage.getItem("businessId") || null;
    if (this.businessId != "" && this.businessId != null) {
      this.categoryAllList();
      this.search();
      this.detailsList();
      this.searchName();
      this.searchGz();
    }

  },
  methods: {
    onlineClick() {
      if(sessionStorage.getItem("userInfo")){
        window.location.href = `http://kefu.mubaiwang.com/html/index.html#/pages/chat/chat?userName=${this.userInfo.userInfo.userName || this.userInfo.userInfo.nickName || ''}&mobileNumber=${this.userInfo.userInfo.mobile || ''}&merchantId=${this.businessId}&nickName=${this.userInfo.userInfo.nickName || ''}&headPortraitImageUrl=${this.userInfo.userInfo.headImageUrl || ''}&appSource='韬儒平台'&deviceSource='微信PC'`
      }else{
        this.$router.push({path:'/login'});
      }
    },
    searchGz(){
      api.businessUserDetail({
        businessId: this.businessId,
      },res=>{
        if(res.data){
            this.follow = false;
        }else{
          this.follow = true;
        }
      })
    },
    followClick(){
    if(sessionStorage.getItem('token')){
     if(this.follow){
        console.log("关注");
        api.businessUserAdd({businessId: this.businessId,
        },res=>{
          if(res.resCode == "0000"){
            this.follow=!this.follow
          }
        })
    }else{
      console.log("取消关注");
      api.businessUserDel({
        businessId: this.businessId,
      },res=>{
        if(res.resCode == "0000"){
          this.follow=!this.follow
        }
      })
    }
    }else{
      this.$router.push({path:'/login'});
    }
      

    },
    searchName(){
      instance.activityBasicPageAll({
        current:this.current,
        size:this.size,
        type:3,
        company_id:this.companyId,
        merchant_id:this.businessId,
        activity_type:100,
      },res=>{
        console.log("res",res);
          this.RecordPageList = res.data.data.records.slice(0, 2) || [];
      })
    },
    routeActiveClick(id){
       this.$router.push({ path: '/dzpActivities', query: {'dzpId':id} });
    },
    point(msg) {
      this.msg = msg;
    },
    businessIdT(msg) {
      this.businessId = msg;
      sessionStorage.setItem("businessId", msg);
    },
    companyIdT(msg) {
      this.companyId = msg;
      sessionStorage.setItem("companyId", msg);
    },
    clickACt(item, index) {
      console.log(index);
      this.curIdx = index;
      sessionStorage.setItem("curIdx", index);
      let name={
            goodsCategoryId: item.id,
            companyId: this.companyId,
            businessId: this.businessId,
      };
      this.$router.push({
        path: item.path,
        query: {'name':JSON.stringify(name)},
      });
    },
    click_left() {
      this.clickCount++;
      this.clickCount > this.hotGoodsList.length - 8 &&
        (this.clickCount = this.hotGoodsList.length - 8);
    },
    click_right() {
      this.clickCount--;
      this.clickCount < 0 && (this.clickCount = 0);
    },
    search() {
      api.taoruBusinessDetail({ id: this.businessId }, (res) => {
        console.log(res);
        this.listData = res.data;
        sessionStorage.setItem("listData", JSON.stringify(res.data));
      });
      api.businessGoodsNewList({ businessId: this.businessId }, (res) => {
        this.newgoods = res.data;
        sessionStorage.setItem("newgoods", JSON.stringify(res.data));
      });
    },
    categoryAllList() {
      api.taoruShopCategoryAllList({ businessId: this.businessId }, (res) => {
        res.data.map((v) => {
          v.path = "/GoodsList";
        });
        res.data.unshift({
          name: "首页",
          path: "/ShopDetail",
        });
        this.navListT = res.data;
        sessionStorage.setItem("navListT", JSON.stringify(res.data));
      });
    },
    detailsList() {
      api.businessGoodsHotList({ businessId: this.businessId }, (res) => {
        console.log(res);
        this.hotGoods = res.data;
        sessionStorage.setItem("hotGoods", JSON.stringify(res.data));
      });
    },
  },
};
</script>

<style lang="less" scoped>
.ShopDetail {
  border-top: 2px solid #299654;
  position: relative;
  .kfBox {
    position: fixed;
    top: 500px;
    right: 40px;
    cursor: pointer;
  }
  header .container {
    height: 117px;
    display: flex;
    align-items: center;
    img {
      max-height: 82px;
      width: auto;
    }
    .advisoryByphone {
      text-align: center;
      margin-right: 200px;
      margin-left: auto;
      img {
        height: 32px;
        width: 32px;
        margin: auto;
      }
      div:nth-child(2) {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-top: 8px;
      }
      div:nth-child(3) {
        height: 29px;
        font-size: 24px;
        font-family: Helvetica-Bold, Helvetica;
        font-weight: bold;
        color: #299654;
        line-height: 29px;
        letter-spacing: 1px;
        margin-top: 10px;
      }
    }
    .advisoryOnline {
      text-align: center;
      img {
        height: 32px;
        width: 32px;
        margin: auto;
      }
      div:nth-child(2) {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-top: 8px;
      }
      div:nth-child(3) {
        width: 149px;
        height: 33px;
        background: #299654;
        border-radius: 4px;
        margin-top: 8px;
        font-size: 18px;
        font-weight: 500;
        color: #ffffff;
        line-height: 25px;
        letter-spacing: 1px;
        line-height: 33px;
      }
    }
  }
  .nav {
    position: absolute;
    z-index: 9;
    width: 100%;
    height: 73px;
    line-height: 73px;
    background: #470c00;
    font-weight: 400;
    color: #ffffff;
    font-size: 24px;
    letter-spacing: 1px;
    -moz-user-select: none; /*火狐*/
    -webkit-user-select: none; /*webkit浏览器*/
    -ms-user-select: none; /*IE10*/
    -khtml-user-select: none; /*早期浏览器*/
    user-select: none;
    .imgLogo {
      // position: absolute;
      // top: 20px;
      // left:100px;
      width: 120px;
      height: 73px;
      margin-right: 20px;
    }
    ul.container {
      display: flex;
      li {
        padding: 0 26px;
        margin-right: 8px;
        cursor: pointer;
        &.active {
          background: #c53f24;
        }
      }
    }
  }
  /deep/.ivu-carousel-arrow {
    width: 60px;
    height: 60px;
    background: rgba(0, 0, 0, 0.5);
    font-size: 28px;
  }
  /deep/.ivu-carousel-dots {
    margin-bottom: 20px;
    li {
      width: 20px;
      height: 20px;
      border: 2px solid #ffffff;
      border-radius: 50%;
      margin-right: 20px;
      &.ivu-carousel-active {
        width: 18px;
        height: 18px;
        background: #ffffff;
        position: relative;
        &::after {
          content: "";
          width: 26px;
          height: 26px;
          display: block;
          position: absolute;
          top: -6px;
          left: -6px;
          background: rgba(255, 255, 255, 0.5);
          border-radius: 50%;
        }
      }
      button {
        opacity: 0;
      }
    }
  }
  .title {
    text-align: center;
    height: 50px;
    font-size: 36px;
    font-weight: 600;
    color: #1a1a1a;
    line-height: 50px;
    letter-spacing: 2px;
    margin-top: 28px;
    margin-bottom: 20px;
    font-family: Helvetica;
    position: relative;
    z-index: 1;
  }
  .ShopDetail_Bg {
    width: 100%;
    height: 1080px;
    // background: url("../../../assets/images/ShopDetail_Bg.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    // z-index: -1;
  }
  .goodsList {
    position: relative;
    z-index: 1;
    ul {
      display: flex;
      width: 100%;
      margin-top: 13px;
      li {
        margin-right: 9px;
        &:last-child(1) {
          margin-right: 0;
        }
        img {
          width: 217px;
          height: 160px;
        }
        div {
          width: 217px;
          margin-top: 8px;
          text-align: center;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 18px;
          font-weight: 400;
          color: #1a1a1a;
          line-height: 25px;
          letter-spacing: 1px;
        }
      }
    }
  }
  .hotGoods {
    width: 1120px;
    // height: 683px;
    background: #ffffff;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    position: relative;
    z-index: 1;
    padding: 24px 28px 40px;
    .hotGoods_top {
      width: 100%;
      height: 459px;
      display: flex;
      .hotGoods_top_left {
        margin-right: 28px;
        img {
          width: 565px;
          height: 459px;
          cursor: pointer;
        }
      }
      .hotGoods_top_right {
        .idx {
          height: 50px;
          font-size: 36px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #e64747;
          line-height: 50px;
          letter-spacing: 2px;
        }
        .goodsName {
          height: 25px;
          font-size: 18px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #1a1a1a;
          line-height: 25px;
          letter-spacing: 1px;
          margin-top: 40px;
        }
        .goodsDesc {
          width: 471px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #838383;
          line-height: 22px;
          letter-spacing: 1px;
          margin-top: 20px;
        }
        .goodsBtn {
          width: 471px;
          height: 68px;
          background: #470c00;
          padding: 9px 16px;
          margin-top: 40px;
          display: flex;
          align-items: center;
          .goodsPrice {
            height: 50px;
            font-size: 36px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 50px;
            letter-spacing: 1px;
          }
          .goodsPayBtn {
            width: 102px;
            height: 35px;
            border: 1px solid #ffffff;
            text-align: center;
            line-height: 35px;
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            margin-left: auto;
            cursor: pointer;
          }
        }
      }
    }
    .click_left,
    .click_right {
      position: absolute;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      font-size: 28px;
      bottom: 86px;
      color: #c0c0c0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .click_left {
      left: 20px;
    }
    .click_right {
      right: 20px;
    }
    .hotGoodsList {
      width: calc(100% - 46px);
      overflow: hidden;
      // margin: 0 24px;
      ul {
        transition: all 1s;
        width: 100%;
        display: flex;
        margin-top: 40px;
        li {
          background: #ffffff;
          margin-right: 8px;
          cursor: pointer;
          &.active {
            border: 1px solid #299654;
          }
          img {
            width: 500px;
            height: 300px;
          }
        }
      }
    }
  }
  .goodListType1 {
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        width: 271px;
        margin-right: 12px;
        background: #ffffff;
        margin-bottom: 13px;
        &:nth-child(4n) {
          margin-right: 0;
        }
        img {
          width: 271px;
          height: 271px;
        }
        .goodsName {
          height: 25px;
          font-size: 18px;
          font-weight: 500;
          color: #1a1a1a;
          line-height: 25px;
          letter-spacing: 1px;
          margin-top: 12px;
          padding: 0 12px;
        }
        .goodsDesc {
          height: 40px;
          font-size: 14px;
          font-weight: 400;
          color: #838383;
          line-height: 20px;
          margin-top: 8px;
          padding: 0 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
        .goodsBtn {
          width: 271px;
          height: 49px;
          background: #470c00;
          display: flex;
          align-items: center;
          margin-top: 24px;
          padding: 0 12px;
          .goodsPrice {
            height: 33px;
            font-size: 24px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 33px;
            letter-spacing: 1px;
          }
          .goodsPayBtn {
            width: 82px;
            height: 28px;
            border: 1px solid #ffffff;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 28px;
            text-align: center;
            cursor: pointer;
            margin-left: auto;
          }
        }
      }
    }
  }
  .goodListType2 {
    margin-bottom: 40px;
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        width: 271px;
        margin-right: 12px;
        background: #ffffff;
        margin-bottom: 13px;
        &:nth-child(4n) {
          margin-right: 0;
        }
        img {
          width: 271px;
          height: 271px;
        }
        .goodsName {
          height: 25px;
          font-size: 18px;
          font-weight: 500;
          color: #1a1a1a;
          line-height: 25px;
          letter-spacing: 1px;
          text-align: center;
          margin-top: 12px;
        }
        .goodsDesc {
          width: 247px;
          font-size: 14px;
          font-weight: 400;
          color: #838383;
          line-height: 20px;
          margin-top: 8px;
          padding: 0 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .goodsBtn {
          width: 271px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          margin-top: 9px;
          .goodsPrice {
            height: 33px;
            font-size: 24px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #470c00;
            line-height: 33px;
            letter-spacing: 1px;
          }
          .goodsPayBtn {
            width: 82px;
            height: 28px;
            border: 1px solid#470C00;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #470c00;
            line-height: 26px;
            text-align: center;
            margin-top: 4px;
            margin-bottom: 12px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.xsActive{
    width: 1120px;
    margin: 40px auto;
      .moreBtn {
        width: 122px;
        height: 33px;
        border: 1px solid #299654;
        margin: 24px auto;
        border-radius: 17px;
        font-size: 18px;
        font-weight: 400;
        color: #299654;
        line-height: 31px;
        text-align: center;
        cursor: pointer;
        position: relative;
        z-index:20;
      }
      .sweepActivitiesB{
        display: flex;
        justify-content: space-between;
        position: relative;
        z-index:21;
      }
      .sweepActivitiesBox{
        display: flex;
        flex-wrap: nowrap;
        cursor: pointer;
      }
      .sweepActivitiesBox:not(:nth-child(2n+1)){
        margin-left:180px;
      }
      .sweepActivitiesLeft{
        width:243px;
        height:120px;
        // background-color: red;
        background-color: white;
        cursor: pointer;
      }
      .sweepActivitiesRight{
        width:210px;
        height:120px;
        margin-left:10px;
      }
      .sweepActivitiesRight>div:nth-child(1){
        // width: 59px;
        height: 21px;
        line-height: 21px;
        text-align: center;
        border-radius: 4px;
        border: 1px solid #F8B60C;
        color:#F8B60C;
        display: inline;
      }
      .sweepActivitiesRight>div:nth-child(2){
        height:46px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #1A1A1A;
        line-height: 22px;
        letter-spacing: 1px;
        margin-top:10px;
      }
      .sweepActivitiesRight>div:nth-child(3){
        height: 17px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #299654;
        line-height: 17px;
      }
    
  }
    .contentBox{
      margin-right:20px;
      width: 80px;
      height: 28px;
      line-height:28px;
      text-align: center;
      background: #FFFFFF;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.11);
      border-radius: 14px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #299654;
      cursor: pointer;
      margin-top:40px;
    }
    .contentBoxO{
      margin-right:20px;
      width: 80px;
      height: 28px;
      background: #E9E9E9;
      border-radius: 14px;
      line-height:28px;
      text-align: center;
      background: #FFFFFF;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.11);
      border-radius: 14px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #838383;
      cursor: pointer;
      margin-top:40px;
    }
</style>